import {AfterViewInit, Component} from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";
import {GalleryImage} from "../common/models/gallery-image";

@Component({
  selector: 'app-gallery',
  standalone: true,
  imports: [
    NgForOf,
    NgOptimizedImage
  ],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss'
})
export class GalleryComponent implements AfterViewInit{

  gallery: Array<GalleryImage> =[
    {path: 'assets/images/qor/2-mini-fracs.jpg', title: ''},
    {path: 'assets/images/qor/3-mini-fracs.jpg', title: ''},
    {path: 'assets/images/qor/25yd-roll-tarp-box.jpg', title: ''},
    {path: 'assets/images/qor/25yd-water-tight.jpg', title: ''},
    {path: 'assets/images/qor/40yd-box-inv.jpg', title: ''},
    {path: 'assets/images/qor/angled-roll-tarp.jpg', title: ''},
    {path: 'assets/images/qor/anthem-1.jpg', title: ''},
    {path: 'assets/images/qor/anthem-2.jpg', title: ''},
    {path: 'assets/images/qor/black-130-bbl.jpg', title: ''},
    {path: 'assets/images/qor/blue-130-bbl.jpg', title: ''},
    {path: 'assets/images/qor/blue-peterbuilt.jpg', title: ''},
    {path: 'assets/images/qor/bobtail-shipyard.jpg', title: ''},
    {path: 'assets/images/qor/bobtail-shipyard2.jpg', title: ''},
    {path: 'assets/images/qor/bobtail-shipyard3.jpg', title: ''},
    {path: 'assets/images/qor/bobtail-shipyard4.jpg', title: ''},
    {path: 'assets/images/qor/box-in-plant.jpg', title: ''},
    {path: 'assets/images/qor/combo-unit.jpg', title: ''},
    {path: 'assets/images/qor/crew-truck1.jpg', title: ''},
    {path: 'assets/images/qor/crew-truck2.jpg', title: ''},
    {path: 'assets/images/qor/crew-truck3.jpg', title: ''},
    {path: 'assets/images/qor/crew-truck4.jpg', title: ''},
    {path: 'assets/images/qor/dh-mini-frac.jpg', title: ''},
    {path: 'assets/images/qor/dh-roll-tarp.jpg', title: ''},
    {path: 'assets/images/qor/dh-stolt.jpg', title: ''},
    {path: 'assets/images/qor/dh-vac-boxes.jpg', title: ''},
    {path: 'assets/images/qor/ent-vac-boxes.jpg', title: ''},
    {path: 'assets/images/qor/f250-1.jpg', title: ''},
    {path: 'assets/images/qor/f250-2.jpg', title: ''},
    {path: 'assets/images/qor/field-services.jpg', title: ''},
    {path: 'assets/images/qor/frac-cleaning.jpg', title: ''},
    {path: 'assets/images/qor/haz-130-bbl.jpg', title: ''},
    {path: 'assets/images/qor/ip-webster.jpg', title: ''},
    {path: 'assets/images/qor/kw-winch-truck.jpg', title: ''},
    {path: 'assets/images/qor/kw-winch-truck2.jpg', title: ''},
    {path: 'assets/images/qor/mini-fracs-1.jpg', title: ''},
    {path: 'assets/images/qor/mini-fracs-2.jpg', title: ''},
    {path: 'assets/images/qor/ohm-130-bbl.jpg', title: ''},
    {path: 'assets/images/qor/ohm-130-bbl2.jpg', title: ''},
    {path: 'assets/images/qor/ohm-130-bbl-3.jpg', title: ''},
    {path: 'assets/images/qor/ohm-130-bbl-4.jpg', title: ''},
    {path: 'assets/images/qor/ohm-130-bbl-5.jpg', title: ''},
    {path: 'assets/images/qor/qor-130.jpg', title: ''},
    {path: 'assets/images/qor/roll-tarp-in-terminal.jpg', title: ''},
    {path: 'assets/images/qor/roll-tarp-sub-station.jpg', title: ''},
    {path: 'assets/images/qor/vac-box-drop.jpg', title: ''},
    {path: 'assets/images/qor/vac-box-inventory.jpg', title: ''},
    {path: 'assets/images/qor/white-130-2.jpg', title: ''},
    {path: 'assets/images/qor/white-130-la.jpg', title: ''},
  ];

  ngAfterViewInit() {
    // @ts-ignore
    $("#js-styl2-mosaic").cubeportfolio({
      filters: "#js-filters-styl2-mosaic",
      loadMore: "#js-loadMore-styl2-mosaic",
      loadMoreAction: "click",
      layoutMode: "mosaic",
      sortToPreventGaps: !0,
      defaultFilter: "*",
      animationType: "quicksand",
      gapHorizontal: 0,
      gapVertical: 0,
      gridAdjustment: "responsive",
      mediaQueries: [{
        width: 1500,
        cols: 5
      }, {
        width: 1100,
        cols: 4
      }, {
        width: 800,
        cols: 3
      }, {
        width: 480,
        cols: 2
      }, {
        width: 320,
        cols: 1
      }],
      caption: "zoom",
      displayType: "fadeIn",
      displayTypeSpeed: 400
    });
  }
}
